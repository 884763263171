import * as React from 'react';
import { Nav, NavMenuItem } from '@components/nav';
import { Box } from './box';
import { BookIcon, HomeIcon, ProductsIcon, TicketsIcon, UserIcon } from '@components/icons';
import { Header } from './header';
import { Footer } from './footer';
import { Container } from './container';
import { usePreferenceContext } from '@src/contexts/preference-context';
import { ROUTES, UserRole } from '@src/constants';
import { NotificationAlertDialog } from './notification-alert-dialog';
import { NotificationsList } from './notification-popup';
import { isNotificationItem } from '@src/types/notification';
import { NotificationItem } from './notification-item';
import { useNotifications } from '@src/contexts/notification-context';
import { useSession } from 'next-auth/react';
import { styled } from '@styles/stitches.config';
import { useTranslation } from 'next-i18next';

type AppLayoutProps = React.PropsWithChildren<{ previewMode?: boolean }>;

function useAllowedMenuItems(role: string[] | undefined): NavMenuItem[] {
  const { t } = useTranslation('MainMenu');

  if (role !== undefined) {
    if (role.includes(UserRole.SUPPORT) || role.includes(UserRole.DEMAND)) {
      return [
        { label: t('menu.home'), href: ROUTES.home, icon: HomeIcon },
        { label: t('menu.tickets'), href: ROUTES.tickets, icon: TicketsIcon },
        { label: t('menu.documents'), href: ROUTES.products, icon: ProductsIcon },
        { label: t('menu.risk-and-compliance'), href: ROUTES.riskCompliance, icon: BookIcon },
        { label: t('menu.logout'), href: ROUTES.logout, icon: UserIcon },
      ];
    }
  }

  return [
    { label: t('menu.home'), href: ROUTES.home, icon: HomeIcon },
    { label: t('menu.logout'), href: ROUTES.logout, icon: UserIcon },
  ];
}

export function AppLayout({ previewMode, children }: AppLayoutProps): JSX.Element {
  const { collapsedMenu } = usePreferenceContext();
  const { data: session } = useSession();
  const { unreadNotifications } = useNotifications();
  const navigationItems = useAllowedMenuItems(session?.user.roles);

  return (
    <Box
      css={{
        display: 'grid',
        minHeight: 'inherit',
        gridTemplateAreas: `
          "header"
          "content"
          "footer"
          "sidebar"
        `,
        gridAutoRows: 'auto 1fr auto auto',
        '@sm': {
          gridTemplateAreas: `
            "sidebar header"
            "sidebar content"
            "sidebar footer"
          `,
          gridTemplateColumns: `minmax(${collapsedMenu ? '64px' : '220px'}, max-content) 1fr`,
          gridAutoRows: 'auto 1fr auto',
        },
      }}
    >
      {unreadNotifications.some((item) => item.level === 'error') && (
        <NotificationAlertDialog>
          <NotificationsList>
            {unreadNotifications.map((item) => {
              return isNotificationItem(item) ? <NotificationItem key={item.sys.id} {...item} /> : null;
            })}
          </NotificationsList>
        </NotificationAlertDialog>
      )}
      <Header />
      <Nav items={navigationItems} />
      <Container
        as="main"
        css={{
          gridArea: 'content',
          py: '$4',
          '@sm': {
            py: '$8',
          },
        }}
      >
        {children}
      </Container>
      {previewMode && <PreviewModeButton href="/api/clear-preview">Disable preview mode</PreviewModeButton>}
      <Footer />
    </Box>
  );
}

const PreviewModeButton = styled('a', {
  position: 'fixed',
  bottom: '$20',
  right: '$4',
  backgroundColor: '$warning100',
  color: '$black',
  padding: '$2 $4',
  fontSize: '$2',
  borderRadius: '$2',
  cursor: 'pointer',

  '@sm': {
    bottom: '$4',
  },
});
