import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import BaseLink, { LinkProps as BaseLinkProps } from 'next/link';

/**
 * Decorated next/link component which passes `href` by default so that `Link`
 * can be wrapped around custom components that render an `a` element
 */
export function Link(props: React.PropsWithChildren<BaseLinkProps>): JSX.Element {
  return <BaseLink passHref={props.passHref ?? true} {...props} legacyBehavior />;
}
