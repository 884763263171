import * as React from 'react';
import { useRouter } from 'next/router';
import FocusTrap from 'focus-trap-react';
import { ChevronUpIcon } from '@components/icons';
import { Box } from '@components/box';
import { IconButton } from '@components/icon-button';
import { Avatar } from '@components/avatar';
import { useLockBodyScroll } from '@utils/use-lock-body-scroll';
import { styled } from '@styles/stitches.config';
import { NavProps } from './nav';
import { NavItem } from './nav-item';
import { useSession } from 'next-auth/react';

const mobileNavToggleHeight = '$space$16';

export function MobileNav({ items }: NavProps): JSX.Element {
  const [isExpanded, setIsExpanded] = React.useState(false);
  useLockBodyScroll(isExpanded);

  return (
    <FocusTrap active={isExpanded}>
      <MobileNavContainer aria-label="Main navigation">
        <MobileNavBar items={items} toggle={() => setIsExpanded((e) => !e)} isExpanded={isExpanded} />
        <MobileNavList items={items} isExpanded={isExpanded} />
      </MobileNavContainer>
    </FocusTrap>
  );
}

const MobileNavContainer = styled('nav', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  background: '$primary400',
  width: '100%',
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
  zIndex: 100,
  '@sm': {
    display: 'none',
  },
});

type MobileNavListProps = NavProps & { isExpanded: boolean };

export function MobileNavList({ items, isExpanded }: MobileNavListProps): JSX.Element {
  const listRef = React.useRef<HTMLUListElement>(null);

  React.useEffect(
    function preventFocusOfMenuItemsWhenNotExpanded() {
      const current = listRef.current;
      if (!current) return;

      if (!isExpanded) {
        current.addEventListener('transitionend', setListVisibilityHidden);

        return () => {
          // Remove listener to prevent callback from executing when menu is toggled to expanded state
          current.removeEventListener('transitionend', setListVisibilityHidden);
        };
      } else {
        current.style.visibility = 'visible';
      }

      function setListVisibilityHidden() {
        current!.style.visibility = 'hidden';
      }
    },
    [isExpanded],
  );

  return (
    <Box
      as="ul"
      ref={listRef}
      css={{
        $$translateY: isExpanded ? 0 : `calc(100% + ${mobileNavToggleHeight})`,
        position: 'absolute',
        bottom: 0,
        left: 0,
        transform: 'translateY($$translateY)',
        transition: 'transform 0.2s',
        overflowY: 'auto',
        width: '100%',
        height: `calc(100vh - ${mobileNavToggleHeight})`,
        background: '$primary400',
        visibility: 'hidden',
        marginBottom: mobileNavToggleHeight,
        '@md': {
          height: `calc(85vh - ${mobileNavToggleHeight})`,
        },
      }}
    >
      {items.map((item, i) => (
        <li key={i}>
          <NavItem {...item} />
        </li>
      ))}
    </Box>
  );
}

type MobileNavToggleProps = NavProps & {
  toggle: () => void;
  isExpanded: boolean;
};

function MobileNavBar({ items, toggle, isExpanded }: MobileNavToggleProps): JSX.Element {
  const { data: session } = useSession();
  const router = useRouter();
  const activeMenuItem = items.find((item) => item.href === router.pathname);

  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: mobileNavToggleHeight,
        width: '100%',
        padding: '$2',
        paddingBottom: 'calc($2 + env(safe-area-inset-bottom))',
        background: '$primary400',
        zIndex: 10,
        [`& > ${NavItem}`]: {
          '@<xxs': {
            display: 'none',
          },
        },
      }}
    >
      <Avatar
        firstName={session?.user.given_name ?? ''}
        lastName={session?.user.family_name ?? ''}
        fullName={session?.user.name ?? ''}
        imgUrl={session?.user.organization.icon ?? ''}
        collapsed
      />
      {activeMenuItem && <NavItem {...activeMenuItem} />}
      <MenuButtonWrapper>
        <MenuButtonText type="button" onClick={toggle} tabIndex={-1}>
          Menu
        </MenuButtonText>
        <IconButton variant="secondary" ariaLabel={isExpanded ? 'Close Menu' : 'Open Menu'} icon={ChevronUpIcon} onClick={toggle} />
      </MenuButtonWrapper>
    </Box>
  );
}

const MenuButtonWrapper = styled('div', { display: 'flex', alignItems: 'stretch' });

const MenuButtonText = styled('button', {
  px: '$2',
  color: '$gray50',
  flex: 1,
  fontWeight: '$bodySemiBold',
});
