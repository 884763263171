import * as React from 'react';
import { HelpcenterIcon } from './icons';
import { Box } from './box';

const HelpCenter = ({ url }: { url: string }): JSX.Element => {
  return (
    <Box as="a" target="_blank" download href={url} css={{ display: 'flex', alignItems: 'center', marginLeft: '$4' }}>
      <HelpcenterIcon width={24} height={24} />
    </Box>
  );
};

export default HelpCenter;
