import * as React from 'react';
import * as Popover from '@radix-ui/react-popover';
import { ChevronDownIcon, CloseIcon } from './icons';
import { keyframes, styled } from '@styles/stitches.config';
import { IconButton } from './icon-button';
import { Box } from './box';
import { Text } from './typography';
import { useRouter } from 'next/router';
import { LanguageItem } from './language-item';
import { useSession } from 'next-auth/react';
import { ContentfulLocales, transformLocalesName, transformLocalesShort } from '@utils/string';
import { DEFAULT_LOCALE } from '@src/constants';

const LanguageSelect = (): JSX.Element => {
  const { locale } = useRouter();
  const { data: session } = useSession();

  return (
    <Popover.Root onOpenChange={(open) => !open} modal={true}>
      <Popover.Trigger data-tracking="open-language-select">
        <Box css={{ display: 'flex', alignItems: 'center', marginLeft: '$4' }}>
          <Text variant="small" css={{ fontWeight: '700', marginTop: '$1' }}>
            {locale?.toUpperCase()}
          </Text>
          <ChevronDownIcon width={24} height={24} />
        </Box>
      </Popover.Trigger>
      <Popover.Portal>
        <StyledContent>
          <ContentHeader>
            <HeaderTopLeft>
              <Text variant="small" css={{ color: '$primary200', fontWeight: '700' }}>
                Language
              </Text>
            </HeaderTopLeft>
            <Popover.Close asChild>
              <IconButton icon={CloseIcon} ariaLabel="Close" data-tracking="close-languages-dropdown" />
            </Popover.Close>
          </ContentHeader>
          <LanguagesList>
            <LanguageDivider />
            <LanguageItem
              title={transformLocalesName(session?.user.organization.language ?? DEFAULT_LOCALE)}
              locale={transformLocalesShort(session?.user?.organization.language ?? DEFAULT_LOCALE)}
            />
            <LanguageDivider />
            {session?.user.organization.alt_languages.flatMap((item, i) => {
              if (!item) return [];

              return (
                <div key={i}>
                  <LanguageItem title={transformLocalesName(item)} locale={transformLocalesShort(item as ContentfulLocales)} />
                  <LanguageDivider />
                </div>
              );
            })}
          </LanguagesList>
          <StyledArrow />
        </StyledContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default LanguageSelect;

export const LanguagesList = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '$4',
});

export const LanguageDivider = styled('hr', {
  backgroundColor: '$gray200',
});

const StyledArrow = styled(Popover.Arrow, {
  fill: 'white',
});

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const StyledContent = styled(Popover.Content, {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$2',
  py: '$4',
  fontSize: 14,
  backgroundColor: 'white',
  minWidth: '15rem',
  maxWidth: '18rem',
  color: 'black',
  zIndex: 99,

  '@md': {
    minWidth: '20rem',
    maxWidth: '28rem',
  },

  boxShadow: '$1',
  transformOrigin: 'var(--radix-popover-content-transform-origin)',
  '&[data-side="top"]': { animationName: slideUp },
  '&[data-side="bottom"]': { animationName: slideDown },
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
});

const ContentHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  px: '$4',
});

const HeaderTopLeft = styled('div', {
  display: 'flex',
  color: '$primary400',
  flexDirection: 'column',
  spaceY: '$2',

  '@md': {
    spaceY: '0',
    spaceX: '$4',
    alignItems: 'center',
    flexDirection: 'row',
    mr: '$4',
  },
});
