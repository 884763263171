import { styled } from '@styles/stitches.config';
import { transitionColors } from '@utils/animations';

export const Anchor = styled('a', {
  color: '$primary400',
  textDecoration: 'underline',
  ...transitionColors,
  '&:hover': {
    color: '$primary500',
  },
});
