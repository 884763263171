/* eslint-disable react/display-name */
import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Text, ListItem, UnorderedList, OrderedList, Heading } from '@components/typography';
import { Anchor } from '@components/anchor';
import { styled } from '@styles/stitches.config';
import type { Document } from '@contentful/rich-text-types';
import type { Options as RtrOptions } from '@contentful/rich-text-react-renderer';
import type { SingleFooterLinkFragment } from '@src/generated/graphql';

type Props = {
  document: Document;
  links?: SingleFooterLinkFragment;
};

export function RichTextRenderer({ document, links }: Props): JSX.Element {
  const assetMap = new Map();

  if (links?.assets) {
    // create an asset map
    // loop through the assets and add them to the map
    links.assets.hyperlink.map((asset) => {
      if (!asset?.url) return [];

      assetMap.set(asset.sys.id, asset);

      return;
    });
  }

  const options: RtrOptions = {
    renderNode: {
      ['heading-1']: (_, children) => {
        return (
          <Heading as="h1" variant="h1">
            {children}
          </Heading>
        );
      },
      ['heading-2']: (_, children) => {
        return (
          <Heading as="h2" variant="h2">
            {children}
          </Heading>
        );
      },
      ['heading-3']: (_, children) => {
        return (
          <Heading as="h3" variant="h3">
            {children}
          </Heading>
        );
      },
      ['heading-4']: (_, children) => {
        return (
          <Heading as="h4" variant="h4">
            {children}
          </Heading>
        );
      },
      ['paragraph']: (_, children) => {
        return <Text as="p">{children}</Text>;
      },
      ['hyperlink']: (node, children) => {
        if (!node?.data?.uri) return children;

        return (
          <Anchor target="_blank" rel="noopener noreferrer" href={node.data.uri}>
            {children}
          </Anchor>
        );
      },
      ['asset-hyperlink']: (node, children) => {
        const asset = assetMap.get(node.data.target.sys.id);
        if (!asset?.url) {
          return <>{children}</>;
        }

        return (
          <Anchor target="_blank" rel="noopener noreferrer" href={asset.url}>
            {children}
          </Anchor>
        );
      },
      ['unordered-list']: (_, children) => {
        return <UnorderedList>{children}</UnorderedList>;
      },
      ['ordered-list']: (_, children) => {
        return <OrderedList>{children}</OrderedList>;
      },
      ['list-item']: (_, children) => {
        return <ListItem>{children}</ListItem>;
      },
    },
    renderMark: {
      ['bold']: (text) => <Text css={{ fontWeight: 'bold' }}>{text}</Text>,
      ['italic']: (text) => <Text css={{ fontStyle: 'italic' }}>{text}</Text>,
      ['underline']: (text) => <Text css={{ textDecoration: 'underline' }}>{text}</Text>,
    },
  };

  return <RichTextContainer>{documentToReactComponents(document, options)}</RichTextContainer>;
}

const RichTextContainer = styled('div', {
  ['> p:not([hidden]) ~ p:not([hidden])']: {
    my: '$4',

    '@lg': {
      my: '$8',
    },
  },
});
