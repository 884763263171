import * as React from 'react';
import { Text } from './typography';
import * as Popover from '@radix-ui/react-popover';
import { useRouter } from 'next/router';
import { styled } from '@styles/stitches.config';
import SvgSuccessCircleBold from './icons/success-circle-bold';
import { MAX_AGE } from '@src/constants';

type LanguageItemProps = {
  title: string;
  locale: string;
};

export const LanguageItem: React.FC<LanguageItemProps> = ({ title, locale }) => {
  const { push, asPath, locale: routerLocale } = useRouter();

  const isActive = routerLocale === locale;

  const handleSwitch = React.useCallback(() => {
    const date = new Date();
    date.setTime(date.getTime() + MAX_AGE.year * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = 'NEXT_LOCALE' + '=' + locale + '; ' + expires + '; path=/';
    void push(asPath, undefined, { locale });
  }, [asPath, push, locale]);

  return (
    <StyledBox css={{ backgroundColor: isActive ? '$primary50' : '' }}>
      {isActive && (
        <StyledIcon>
          <SvgSuccessCircleBold />
        </StyledIcon>
      )}
      <StyledButton onClick={() => handleSwitch()}>
        <Popover.Close asChild>
          <Text
            variant="small"
            css={{
              display: 'block',
              color: '$primary400',
              fontFamily: '$heading',
              fontSize: '$lg',
              py: '$2',
              paddingLeft: '$10',
            }}
          >
            {title}
          </Text>
        </Popover.Close>
      </StyledButton>
    </StyledBox>
  );
};

const StyledBox = styled('li', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'stretch',
  '&:hover': {
    backgroundColor: '$primary50',
  },
});

const StyledIcon = styled('div', {
  color: '$primary400',
  left: '$4',
  position: 'absolute',
});

const StyledButton = styled('button', {
  textAlign: 'left',
  width: '100%',
});
