import * as React from 'react';

function SvgSuccessCircleBold(props: React.SVGProps<SVGSVGElement>) {
  return (
    <>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 1.5C3.96243 1.5 1.5 3.96243 1.5 7C1.5 10.0376 3.96243 12.5 7 12.5C10.0376 12.5 12.5 10.0376 12.5 7C12.5 3.96243 10.0376 1.5 7 1.5ZM0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.35355 5.31311C9.54882 5.50838 9.54882 5.82496 9.35355 6.02022L6.68689 8.68689C6.49162 8.88215 6.17504 8.88215 5.97978 8.68689L4.64645 7.35355C4.45118 7.15829 4.45118 6.84171 4.64645 6.64645C4.84171 6.45118 5.15829 6.45118 5.35355 6.64645L6.33333 7.62623L8.64645 5.31311C8.84171 5.11785 9.15829 5.11785 9.35355 5.31311Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 1.5C3.96243 1.5 1.5 3.96243 1.5 7C1.5 10.0376 3.96243 12.5 7 12.5C10.0376 12.5 12.5 10.0376 12.5 7C12.5 3.96243 10.0376 1.5 7 1.5ZM0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z"
          stroke="#572179"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.35355 5.31311C9.54882 5.50838 9.54882 5.82496 9.35355 6.02022L6.68689 8.68689C6.49162 8.88215 6.17504 8.88215 5.97978 8.68689L4.64645 7.35355C4.45118 7.15829 4.45118 6.84171 4.64645 6.64645C4.84171 6.45118 5.15829 6.45118 5.35355 6.64645L6.33333 7.62623L8.64645 5.31311C8.84171 5.11785 9.15829 5.11785 9.35355 5.31311Z"
          stroke="#572179"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default SvgSuccessCircleBold;
