import { styled } from '@styles/stitches.config';

export const Container = styled('div', {
  width: '100%',
  maxWidth: 1400,
  mx: 'auto',
  px: '$4',
  '@sm': {
    px: '$8',
  },
});
