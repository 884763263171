import * as React from 'react';
import { Link } from '@lib/link';
import { Box } from './box';
import { Container } from './container';
import { Logo } from './logo';
import { styled } from '@styles/stitches.config';
import { ROUTES } from '@src/constants/routes';
import NotificationPopup from './notification-popup';
import LanguageSelect from './language-select';
import { useSession } from 'next-auth/react';
import HelpCenter from './help-center';
import { useQuery } from 'react-query';
import { UserManualItemFragment } from '@src/generated/graphql';
import { useRouter } from 'next/router';

export function Header(): JSX.Element {
  const { data: session } = useSession();
  const { locale } = useRouter();

  const {
    data: userManual,
    isLoading,
    isError,
  } = useQuery(
    ['userManual', locale],
    async () => {
      const res = await fetch(`/api/user-manual?locale=${locale}`);

      return res.json() as Promise<UserManualItemFragment>;
    },
    { enabled: !!session && !!locale },
  );

  return (
    <Container as="header" css={{ gridArea: 'header' }}>
      <Box css={{ py: '$3', borderBottom: '1px solid $gray100' }}>
        <Box css={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link href={ROUTES.home}>
            <a aria-label="Stater Account">
              <Logo width={156} />
            </a>
          </Link>
          <ActionsContainer>
            <NotificationPopup />
            {session?.user && session.user.organization.alt_languages.length > 0 && <LanguageSelect />}

            {userManual?.pdf?.url && !isLoading && !isError && <HelpCenter url={userManual.pdf.url} />}
          </ActionsContainer>
        </Box>
      </Box>
    </Container>
  );
}

const ActionsContainer = styled('div', {
  display: 'flex',
  alignSelf: 'center',
  color: '$primary400',
});
