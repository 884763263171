import * as StitchesAlertDialog from '@radix-ui/react-alert-dialog';
import { CloseIcon, NotificationActiveIcon } from './icons';
import { styled } from '@styles/stitches.config';
import { Box } from './box';
import { IconButton } from './icon-button';
import { useNotifications } from '@src/contexts/notification-context';
import { WithChildren } from '@src/types/react';
import { useTranslation } from 'next-i18next';

export const NotificationAlertDialog: React.FC<WithChildren> = ({ children }) => {
  const { setNotificationsRead } = useNotifications();
  const { t } = useTranslation('Notifications');

  return (
    <StitchesAlertDialog.Root defaultOpen={true} onOpenChange={(open) => !open && setNotificationsRead()}>
      <StyledOverlay />
      <StyledContent>
        <ContentHeader>
          <HeaderTopLeft>
            <Box css={{ display: 'flex', alignItems: 'center', spaceX: '$2' }}>
              <NotificationActiveIcon width={16} height={16} />

              <StyledTitle>{t('notifications.title')}</StyledTitle>
            </Box>
          </HeaderTopLeft>
          <StitchesAlertDialog.Cancel asChild>
            <IconButton icon={CloseIcon} ariaLabel="Close" data-tracking="close-notifications-dropdown" />
          </StitchesAlertDialog.Cancel>
        </ContentHeader>
        {children}
      </StyledContent>
    </StitchesAlertDialog.Root>
  );
};

const StyledOverlay = styled(StitchesAlertDialog.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, .15)',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 100,
});

const StyledTitle = styled(StitchesAlertDialog.Title, {
  fontFamily: '$heading',
  fontWeight: 400,
  color: '$primary200',
  fontSize: '$xs',
  '@xl': {
    fontSize: '$sm',
  },
});

const StyledContent = styled(StitchesAlertDialog.Content, {
  position: 'fixed',
  top: '4rem',
  right: '1rem',
  width: 460,
  backgroundColor: 'white',
  borderRadius: 6,
  padding: 20,
  zIndex: 101,
});

const ContentHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const HeaderTopLeft = styled('div', {
  display: 'flex',
  color: '$primary400',
  flexDirection: 'column',
  spaceY: '$2',

  '@md': {
    spaceY: '0',
    spaceX: '$4',
    alignItems: 'center',
    flexDirection: 'row',
    mr: '$4',
  },
});
