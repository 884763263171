import * as React from 'react';
import { styled } from '@styles/stitches.config';
import { CheckmarkIcon, InfoIcon, WarningIcon } from './icons';
import { Heading, Text } from './typography';
import { NotificationType } from '@src/types/notification';
import { WithChildren } from '@src/types/react';

const iconOptions = {
  info: InfoIcon,
  success: CheckmarkIcon,
  warning: WarningIcon,
  error: WarningIcon,
};

type NotificationItemProps = Pick<NotificationType, 'title' | 'level' | 'message'>;

export const NotificationItem: React.FC<WithChildren<NotificationItemProps>> = ({ title, level, message, ...props }) => {
  const IconComponent = iconOptions[level];

  return (
    <StyledNotificationItem {...props}>
      <NotificationHeader>
        <NotificationIcon aria-hidden type={level}>
          <IconComponent width={16} height={16} />
        </NotificationIcon>
        <Heading as="h5" variant="h5" css={{ color: '$black' }}>
          {title}
        </Heading>
      </NotificationHeader>
      <NotificationContent>
        <Text variant="small">{message}</Text>
      </NotificationContent>
    </StyledNotificationItem>
  );
};

const StyledNotificationItem = styled('li', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

const NotificationIcon = styled('div', {
  display: 'flex',
  borderRadius: '$full',
  p: '$3',
  backgroundColor: '$warning50',
  color: '$warning200',
  boxShadow: '$1',
  strokeWidth: '2',
  variants: {
    type: {
      info: {
        color: '$tertiary400',
        backgroundColor: '$tertiary50',
      },
      warning: {
        color: '$warning200',
        backgroundColor: '$warning50',
      },
      success: {
        color: '$success200',
        backgroundColor: '$success50',
      },
      error: {
        color: '$error200',
        backgroundColor: '$error50',
      },
    },
  },
  defaultVariants: {
    type: 'warning',
  },
});

const NotificationContent = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',

  '@md': {
    px: '$12',
  },
});

const NotificationHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  spaceX: '$2',
  width: '100%',
  mb: '$4',
});
