import { SingleFooterLinkFragment } from '@src/generated/graphql';
import { styled } from '@styles/stitches.config';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { Container } from './container';
import { RichTextRenderer } from './rich-text-renderer';

type FooterDataResult =
  | {
      json: any;
      links: SingleFooterLinkFragment;
    }
  | null
  | undefined;

async function getFooterData(locale: string): Promise<FooterDataResult> {
  const res = await fetch(`/api/footer?locale=${locale}`);

  return res.json() as Promise<FooterDataResult>;
}

export function Footer(): JSX.Element {
  const { locale } = useRouter();
  const { data: footerData } = useQuery(['footerData', locale], () => getFooterData(locale!), { enabled: !!locale });

  return (
    <Container as="footer" css={{ gridArea: 'footer' }}>
      {footerData && (
        <RichTextContainer>
          <RichTextRenderer document={footerData.json} links={footerData.links} />
        </RichTextContainer>
      )}
    </Container>
  );
}

const RichTextContainer = styled('div', {
  py: '$3',
  display: 'flex',
  justifyContent: 'center',
  color: '$primary200',
  borderTop: '1px solid $gray100',
  ' p': {
    '@xl': {
      fontSize: '$sm',
    },
  },
  ['p > a']: {
    color: '$primary200',
  },
});
