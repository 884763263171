import { styled } from '@styles/stitches.config';

/**
 * Text is hidden visually but is not hidden from screen readers
 */
export const VisuallyHiddenText = styled('span', {
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: '0',
});
