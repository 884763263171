import * as React from 'react';
import { LoaderIcon } from '@components/icons';
import { styled } from '@styles/stitches.config';
import { spin } from '@styles/animation';
import { IconComponent } from './icon-component';
import { transitionColors } from '@utils/animations';

type IconButtonProps = React.ComponentProps<typeof IconButtonContainer> & {
  loading?: boolean;
  icon: IconComponent;
  /** Label for a11y purposes */
  ariaLabel: string;
};

export const IconButtonContainer = styled('button', {
  position: 'relative',
  alignItems: 'center',
  borderRadius: '9999px',
  display: 'inline-flex',
  justifyContent: 'center',
  size: '$10',
  ...transitionColors,
  fontSize: '$base',
  ring: true,
  $$ringColor: '$colors$tertiary300',
  $$ringBoxShadow: '$shadows$1',
  '& .svgIcon': {
    transitionDuration: '100ms',
    transitionProperty: 'color, font-size',
    fontSize: '$2xl',
    userSelect: 'none',
  },
  '& .loader-icon': {
    animationName: spin,
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: '$primary50',
        color: '$primary400',
        '&:hover': {
          backgroundColor: '$primary400',
          color: '$white',
        },
        '&:active': {
          backgroundColor: '$primary50',
          color: '$primary400',
        },
      },
      secondary: {
        backgroundColor: '$primary50',
        color: '$black',
        '&:active': {
          backgroundColor: '$primary100',
        },
      },
      tertiary: {
        color: '$black',
        background: '$tertiary50',
        '&:hover': {
          color: '$tertiary400',
        },
        '&:active': {
          backgroundColor: '$tertiary100',
          color: '$black',
        },
      },
      disabled: {
        color: '$gray400',
        background: '$gray200',
        cursor: 'not-allowed',
        '& .svgIcon': {
          fontSize: '1.5rem',
        },
      },
    },
    isLoading: {
      true: {
        userSelect: 'none',
        cursor: 'progress',
        fontSize: '1.5rem',
        '& .loader-icon': {
          color: '$black',
        },
        '& .svgIcon': {
          visibility: 'hidden',
        },
      },
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      isLoading: true,
      css: {
        backgroundColor: '$primary400',
        '&:active': {
          backgroundColor: '$primary400',
        },
        '& .loader-icon': {
          color: '$white',
        },
      },
    },
    {
      variant: 'secondary',
      isLoading: true,
      css: {
        backgroundColor: '$tertiary50',
        '&:active': {
          backgroundColor: '$tertiary50',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'primary',
  },
});

const LoaderIconContainer = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ ariaLabel, disabled, loading, variant, icon: Icon, ...props }, ref) => {
    return (
      <IconButtonContainer
        ref={ref}
        type="button"
        aria-label={ariaLabel}
        disabled={disabled || loading}
        variant={disabled ? 'disabled' : variant}
        isLoading={loading}
        {...props}
      >
        {loading && (
          <LoaderIconContainer>
            <LoaderIcon width={24} height={24} className="loader-icon" />
          </LoaderIconContainer>
        )}
        {Icon && <Icon className="svgIcon" aria-hidden="true" />}
      </IconButtonContainer>
    );
  },
);

IconButton.displayName = 'IconButton';
