import * as React from 'react';
import { styled, CSS } from '@styles/stitches.config';
import { InfoIcon, SuccessGreenIcon, WarningIcon } from './icons';
import { Text } from './typography';
import { WithChildren } from '@src/types/react';

const DEFAULT_TAG = 'a';

const AlertBase = styled(DEFAULT_TAG, {
  display: 'flex',
  flexDirection: 'row',
  color: '$primary400',
  alignItems: 'center',
  p: '$4',
  borderRadius: '$2',
  spaceX: '$2',

  variants: {
    status: {
      notification: {
        color: '$notification200',
        border: '1px solid $notification200',
        backgroundColor: '$notification50',
      },
      warning: {
        color: '$warning200',
        border: '1px solid $warning200',
        backgroundColor: '$warning50',
      },
      success: {
        color: '$success200',
        border: '1px solid $success200',
        backgroundColor: '$success50',
      },
      error: {
        color: '$error200',
        border: '1px solid $error200',
        backgroundColor: '$error50',
      },
    },
  },
  defaultVariants: {
    status: 'notification',
  },
});

const icons = {
  notification: InfoIcon,
  success: SuccessGreenIcon,
  warning: InfoIcon,
  error: WarningIcon,
};

type AlertProps = {
  as?: 'a' | 'div';
  status?: keyof typeof icons;
  withIcon?: boolean;
  css?: CSS;
};

export const Alert: React.FC<WithChildren<AlertProps>> = ({ as = DEFAULT_TAG, status = 'notification', withIcon = false, ...props }) => {
  const IconComponent = icons[status];

  return (
    <AlertBase as={as} status={status} {...props}>
      {withIcon && <IconComponent width="16" height="16" />} <Text variant="label">{props.children}</Text>
    </AlertBase>
  );
};
