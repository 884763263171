import * as React from 'react';
import { Avatar } from '@components/avatar';
import { MobileNav } from './mobile-nav';
import { styled } from '@styles/stitches.config';
import { IconComponent } from '@components/icon-component';
import { NavItem, truncateText } from './nav-item';
import { useSession } from 'next-auth/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@components/icons';
import { usePreferenceContext } from '@src/contexts/preference-context';
import { Text } from '@components/typography';
import { transitionColors } from '@utils/animations';
import { useTranslation } from 'next-i18next';

export type NavProps = {
  items: NavMenuItem[];
};

export type NavMenuItem = {
  label: string;
  href: string;
  icon: IconComponent;
  isCollapsed?: boolean;
};

export function Nav(props: NavProps): JSX.Element {
  return (
    <>
      <MobileNav {...props} />
      <DesktopNav {...props} />
    </>
  );
}

function DesktopNav({ items }: NavProps): JSX.Element {
  const { t } = useTranslation('MainMenu');
  const { data: session } = useSession();
  const { collapsedMenu: isCollapsed, setCollapsedMenu } = usePreferenceContext();

  const setCollapsedCookie = React.useCallback(() => {
    setCollapsedMenu(!isCollapsed);
  }, [isCollapsed, setCollapsedMenu]);

  return (
    <DesktopNavContainer aria-label="Main navigation" collapsed={isCollapsed}>
      <Avatar
        firstName={session?.user.given_name ?? ''}
        lastName={session?.user.family_name ?? ''}
        fullName={session?.user.name ?? ''}
        imgUrl={session?.user.organization.icon ?? ''}
        collapsed={isCollapsed}
      />
      <CollapseIconButton
        onClick={setCollapsedCookie}
        aria-label={`${isCollapsed ? 'Open' : 'Close'} menu`}
        title={`${isCollapsed ? 'Open' : 'Close'} menu`}
      >
        <CollapseIconContainer>{isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}</CollapseIconContainer>
        <Text variant="small">{t('menu.fold')}</Text>
      </CollapseIconButton>
      <ul>
        {items.map((item, i) => (
          <li key={i}>
            <NavItem {...item} isCollapsed={isCollapsed} />
          </li>
        ))}
      </ul>
    </DesktopNavContainer>
  );
}

// This is almost the same as an icon button...
const CollapseIconContainer = styled('div', {
  p: '$1',
  mr: '$2',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  [`& > svg`]: {
    color: 'white',
  },
});

const CollapseIconButton = styled('button', {
  display: 'flex',
  color: '$gray50',
  alignItems: 'center',
  p: '$3',
  ring: true,
  ringInset: 'inset',
  ...truncateText,
  ...transitionColors,

  [`& ${CollapseIconContainer}`]: {
    background: '$primary200',
    size: '$10',
    borderRadius: '9999px',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
});

const DesktopNavContainer = styled('nav', {
  display: 'none',
  position: 'sticky',
  top: 0,
  height: '100vh',
  flexDirection: 'column',
  background: '$primary400',
  width: '100%',
  gridArea: 'sidebar',
  boxShadow: '$1',
  [`& > ${Avatar}`]: {
    m: '$4',
  },
  [`& ${CollapseIconContainer}`]: {
    p: '$3',
  },
  '@sm': {
    display: 'flex',
  },

  variants: {
    collapsed: {
      true: {
        [`& > ${Avatar}`]: {
          mx: '$2',
          px: '$2',
        },

        [`& > ${CollapseIconButton}`]: {
          px: '$3',
          ['& > span']: {
            opacity: '0',
          },
        },

        [`& ${CollapseIconContainer}`]: {
          mr: '0',
        },
        maxWidth: '$16',
      },
      false: {
        maxWidth: '220px',
      },
    },
  },
  defaultVariants: {
    collapsed: false,
  },
});
