import * as React from 'react';
import { useRouter } from 'next/router';
import { Link } from '@lib/link';
import { CSS, styled } from '@styles/stitches.config';
import { NavMenuItem } from './nav';
import { transitionColors } from '@utils/animations';

type NavItemProps = NavMenuItem;

export function NavItem({ href, label, isCollapsed = false, icon: Icon }: NavItemProps): JSX.Element {
  const router = useRouter();
  const active = isRouteActive(router.pathname, href);

  function isRouteActive(pathname: string, href: string): boolean {
    let s = pathname;

    if (s.includes('[')) s = s.slice(0, s.indexOf('['));
    if (s.length > 1 && s.endsWith('/')) s = s.slice(0, s.length - 1);

    return s === href;
  }

  return (
    <Link href={href}>
      <NavLinkAnchor title={label} active={active} collapsed={isCollapsed}>
        <NavLinkIconContainer>
          <Icon width={24} height={24} aria-label={label} />
        </NavLinkIconContainer>
        <NavLinkLabel aria-hidden>{label}</NavLinkLabel>
      </NavLinkAnchor>
    </Link>
  );
}

NavItem.toString = () => NavLinkAnchor.toString();

const NavLinkIconContainer = styled('div', {
  p: '$1',
  mr: '$2',
  background: '$primary200',
  borderRadius: '4px',
  ...transitionColors,
});

export const truncateText: CSS = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const NavLinkLabel = styled('span', {
  fontWeight: '$bodySemiBold',
  letterSpacing: 1,
  ...truncateText,
  ...transitionColors,
});

const NavLinkAnchor = styled('a', {
  display: 'flex',
  color: '$gray50',
  alignItems: 'center',
  p: '$4',
  ring: true,
  ringInset: 'inset',
  ...truncateText,
  ...transitionColors,
  '&:hover': {
    background: '$primary200',
  },
  variants: {
    active: {
      true: {
        [`& ${NavLinkIconContainer}`]: {
          background: '$secondary400',
        },
        [`& ${NavLinkLabel}`]: {
          color: '$secondary400',
        },
      },
    },
    collapsed: {
      true: {
        justifyContent: 'center',

        [`& ${NavLinkIconContainer}`]: {
          mr: '0',
        },
        [`& ${NavLinkLabel}`]: {
          opacity: '0',
          width: '0',
        },
      },
    },
  },
});
