import * as React from 'react';
import Image from 'next/legacy/image';
import { CSS, styled } from '@styles/stitches.config';
import { Text } from './typography';
import { Link } from '@lib/link';
import { VisuallyHiddenText } from './visually-hidden-text';
import { ROUTES } from '@src/constants';

const className = 'avatar';

type AvatarProps = {
  imgUrl?: string;
  fullName: string;
  firstName: string;
  lastName: string;
  collapsed?: boolean;
  css?: CSS;
};

export function Avatar({ imgUrl, fullName, firstName, lastName, collapsed = false, css }: AvatarProps): JSX.Element {
  const avatarAbbreviations = firstName.charAt(0) + lastName.charAt(0);

  return (
    <Link href={ROUTES.profile}>
      <AvatarContainer collapsed={collapsed} css={css} className={className} aria-label="Account page">
        {imgUrl ? (
          <ImageContainer>
            <Image src={imgUrl} width={32} height={32} alt="" />
          </ImageContainer>
        ) : (
          <AvatarNameContainer>
            <AvatarNameAbbreviations aria-hidden>{avatarAbbreviations}</AvatarNameAbbreviations>
          </AvatarNameContainer>
        )}
        {!collapsed && <AvatarName aria-hidden>{fullName}</AvatarName>}
        <VisuallyHiddenText>{fullName}</VisuallyHiddenText>
      </AvatarContainer>
    </Link>
  );
}

Avatar.toString = () => `.${className}`;

const AvatarContainer = styled('a', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '$2',
  backgroundColor: '$primary200',
  color: '$white',
  px: '$4',
  py: '$2',
  ring: true,

  variants: {
    collapsed: {
      true: {
        px: '$2',
      },
    },
  },
});

const AvatarNameAbbreviations = styled('span', {
  fontFamily: '$heading',
  fontWeight: 400,
  fontSize: '$xs',
  textTransform: 'uppercase',
});

const AvatarNameContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$full',
  border: '2px solid $white',
  size: '$8',
  flexShrink: 0,
  backgroundColor: '$primary400',
});

const AvatarName = styled(Text, {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ml: '$1',
});

const ImageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$full',
  border: '2px solid $white',
  size: '$8',
  flexShrink: 0,
  backgroundColor: '$white',

  '& img': {
    borderRadius: '$full',
  },
});
