import * as React from 'react';
import * as Popover from '@radix-ui/react-popover';
import { CloseIcon, NotificationActiveIcon, NotificationDefaultIcon } from './icons';
import { keyframes, styled } from '@styles/stitches.config';
import { Link } from '@lib/link';
import { ROUTES } from '@src/constants';
import { Alert } from './alert';
import { IconButton } from './icon-button';
import { Box } from './box';
import { Heading, Text } from './typography';
import { AnchorButton } from './button';
import { NotificationItem } from './notification-item';
import { isNotificationItem } from '@src/types/notification';
import { useNotifications } from '@src/contexts/notification-context';
import { useTranslation } from 'next-i18next';

const NotificationPopup = (): JSX.Element => {
  const { allNotifications, unreadNotifications, setNotificationsRead } = useNotifications();
  const { t } = useTranslation('Notifications');

  const NotificationIcon = unreadNotifications.length !== 0 ? NotificationActiveIcon : NotificationDefaultIcon;

  return (
    <Popover.Root onOpenChange={(open) => !open && setNotificationsRead()} modal={true}>
      <Popover.Trigger data-tracking="open-notifications-dropdown">
        <NotificationIcon width={24} height={24} />
      </Popover.Trigger>
      <Popover.Portal>
        <StyledContent>
          <ContentHeader>
            <HeaderTopLeft>
              <Box css={{ display: 'flex', alignItems: 'center', spaceX: '$2' }}>
                <NotificationIcon width={16} height={16} />

                <Text variant="small" css={{ color: '$primary200' }}>
                  {t('notifications.title')}
                </Text>
              </Box>
              {unreadNotifications.length !== 0 && (
                <Heading as="span" variant="h6" css={{ textTransform: 'uppercase', color: '$black' }}>
                  {unreadNotifications.length} nieuwe meldingen
                </Heading>
              )}
            </HeaderTopLeft>
            <Popover.Close asChild>
              <IconButton icon={CloseIcon} ariaLabel="Close" data-tracking="close-notifications-dropdown" />
            </Popover.Close>
          </ContentHeader>
          <NotificationsDivider />
          <StyledArrow />
          <NotificationsList>
            {unreadNotifications?.map((item) => {
              return isNotificationItem(item) ? <NotificationItem key={item.sys.id} {...item} /> : null;
            })}

            {unreadNotifications.length === 0 && <Alert withIcon>{t('notifications.newnotificationstext')}</Alert>}
          </NotificationsList>
          <NotificationsDivider />
          {allNotifications && allNotifications.length > 0 && (
            <Box>
              <Link href={ROUTES.profileNotifications}>
                <AnchorButton onClick={setNotificationsRead}>
                  {t('notifications.button')} ({allNotifications.length})
                </AnchorButton>
              </Link>
            </Box>
          )}
        </StyledContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default NotificationPopup;

export const NotificationsList = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  mt: '$2',
});

export const NotificationsDivider = styled('hr', {
  backgroundColor: '$gray200',
  my: '$4',
});

const StyledArrow = styled(Popover.Arrow, {
  fill: 'white',
});

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const StyledContent = styled(Popover.Content, {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$2',
  padding: '$4',
  fontSize: 14,
  backgroundColor: 'white',
  minWidth: '15rem',
  maxWidth: '18rem',
  color: 'black',
  zIndex: 99,

  '@md': {
    minWidth: '20rem',
    maxWidth: '28rem',
  },

  boxShadow: '$1',
  transformOrigin: 'var(--radix-popover-content-transform-origin)',
  '&[data-side="top"]': { animationName: slideUp },
  '&[data-side="bottom"]': { animationName: slideDown },
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
});

const ContentHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const HeaderTopLeft = styled('div', {
  display: 'flex',
  color: '$primary400',
  flexDirection: 'column',
  spaceY: '$2',

  '@md': {
    spaceY: '0',
    spaceX: '$4',
    alignItems: 'center',
    flexDirection: 'row',
    mr: '$4',
  },
});
